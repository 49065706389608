import React, { useState, useMemo } from 'react';
import { above } from 'src/components/global/mediaqueries';
import AddToCartButton from 'src/components/buttons/AddToCartButton';
import Breadcrumbs from 'src/components/Breadcrumbs';
import ErrorCatcher from 'src/components/ErrorCatcher';
import formatProductDate from 'src/components/product/formatProductDate';
import htmlDecode from 'src/utils/htmlDecode';
import Modules from 'src/load-module';
import ProductGallery from 'src/components/productGallery/ProductGallery';
import ProductHighlightList from 'src/components/product/highlight/ProductHighlightList';
import ProductHighlightText from 'src/components/product/highlight/ProductHighlightText';
import ProductInfo from 'src/components/productInfo/ProductInfo';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Tag from 'src/components/typography/Tag';
import Text from 'src/components/Text';
import theme from 'src/styles/theme';
import ToggableText from 'src/components/ToggableText';
import VariationsPicker from 'src/components/ProductVariationsPicker';
import WysiwygWrapper from 'src/components/WysiwygWrapper';

const ProductWrapper = styled('div')`
    background-color: ${theme.color.white};
    color: ${theme.color.black};

    ${above.tablet_s} {
        display: flex;
    }
`;

const GalleryWrapper = styled('div')`
    background-image: ${theme.color.white};

    ${above.tablet_s} {
        flex: 0 0 50%;
    }
`;

const InfoWrapper = styled('div')`
    ${theme.spacing.mobile._24('padding-top')};
    ${theme.spacing.mobile._12('padding-right')};
    ${theme.spacing.mobile._12('padding-left')};
    ${theme.spacing.mobile._40('padding-bottom')};

    ${theme.spacing.tablet._24('padding-top')};
    ${theme.spacing.tablet._20('padding-right')};
    ${theme.spacing.tablet._40('padding-bottom')};

    ${theme.spacing.desktop._32('padding-top')};
    ${theme.spacing.desktop._32('padding-right')};
    ${theme.spacing.desktop._32('padding-bottom')};

    background-image: ${theme.color.white};

    ${above.tablet_s} {
        flex: 0 0 50%;
        padding-left: 0;
    }
`;

const ProductMeta = styled('div')`
    font-size: 0;

    span {
        font-size: 12px;
        ${theme.spacing.mobile._12('margin-right')};
        ${theme.spacing.tablet._12('margin-right')};
        ${theme.spacing.desktop._12('margin-right')};
    }

    ${theme.spacing.mobile._8('margin-top')};
    ${theme.spacing.tablet._8('margin-top')};
    ${theme.spacing.desktop._8('margin-top')};

    ${theme.spacing.mobile._16('margin-bottom')};
    ${theme.spacing.tablet._16('margin-bottom')};
    ${theme.spacing.desktop._32('margin-bottom')};
`;

const ProductName = styled('div')`
    ${theme.spacing.mobile._24('margin-bottom')};
    ${theme.spacing.tablet._24('margin-bottom')};
    ${theme.spacing.desktop._24('margin-bottom')};

    ${theme.fontSize.serif.mobile._20};
    ${theme.fontSize.serif.tablet._20};
    ${theme.fontSize.serif.desktop._32};
`;

const ProductText = styled('div')`
    ${theme.spacing.mobile._32('margin-bottom')};
    ${theme.spacing.tablet._32('margin-bottom')};
    ${theme.spacing.desktop._32('margin-bottom')};

    font-size: 16px;
    line-height: 1.6em;
    font-weight: 400;
`;

const AddToCartButtonWrapper = styled('div')`
    ${theme.spacing.mobile._24('margin-top')};
    ${theme.spacing.tablet._24('margin-top')};
    ${theme.spacing.desktop._20('margin-top')};

    ${theme.spacing.mobile._24('margin-bottom')};
    ${theme.spacing.tablet._24('margin-bottom')};
    ${theme.spacing.desktop._32('margin-bottom')};
`;

const StyledText = styled(Text)`
    line-height: 1.6em;
`;

const BreadcrumbsWrapper = styled('div')`
    z-index: 100;
    width: 100%;

    ${above.tablet_s} {
        width: 50%;
        position: absolute;
    }

    ${theme.spacing.mobile._16('padding-top')};
    ${theme.spacing.mobile._12('padding-left')};
    ${theme.spacing.mobile._24('padding-right')};

    ${theme.spacing.tablet._24('padding-top')};
    ${theme.spacing.tablet._20('padding-left')};
    ${theme.spacing.tablet._40('padding-right')};

    ${theme.spacing.desktop._32('padding-top')};
    ${theme.spacing.desktop._32('padding-left')};
    ${theme.spacing.desktop._64('padding-right')};
`;

const ProductPage = ({ data, product }) => {
    const [checked, setChecked] = useState(0);
    const activeVariation = product.acfData.productInformation.variations[checked];
    const mainCategoryPermalink = JSON.parse(data.mainCategory.edges[0].node.acf_data).permalink;

    const missingProductImage = product.productImage.fluid.src.indexOf('acf-logo.png') !== -1;
    const missingGalleryImages = product.imageData[0].fluid.src.indexOf('acf-logo.png') !== -1;

    const imageData = [];

    if (!missingProductImage) {
        imageData.push(product.productImage);
    }

    if (!missingGalleryImages) {
        for (let i = 0; i < product.imageData.length; i++) {
            imageData.push(product.imageData[i]);
        }
    }

    const renderPageContent = useMemo(() => {
        return (
            product.acfData.pageContent.map((productModuleData, index) =>
                <ErrorCatcher key={index}>
                    {      
                        React.createElement(Modules(productModuleData.module), {
                            key: index,
                            module: productModuleData.module,
                            title: '',
                            data: productModuleData.data,
                        })
                    }
                </ErrorCatcher>
            )
        );
    }, [product]);

    return (
        <>
            <ProductWrapper>
                <GalleryWrapper>
                    <BreadcrumbsWrapper>
                        <Breadcrumbs previousPages={ { name: htmlDecode(product.mainCategoryName), link: mainCategoryPermalink } } pageTitle={product.name} />
                    </BreadcrumbsWrapper>
                    <ProductGallery images={imageData} />
                </GalleryWrapper>
                <InfoWrapper>
                    <Tag>{htmlDecode(product.mainCategoryName)}</Tag>
                    <ProductMeta>
                        <span>Published date: {formatProductDate(product.published)}</span> <span>{product.edition}</span>
                    </ProductMeta>
                    <ProductName>{product.name}</ProductName>
                    <ProductText>
                        <ToggableText>
                            <WysiwygWrapper textComponent={StyledText} data={product.acfData.productInformation.fullDescription} />
                        </ToggableText>
                    </ProductText>
                    {product.acfData.productInformation.readMore && (
                        <ProductInfo productInformation={product.acfData.productInformation.readMore} title={product.name} />
                    )}

                    <VariationsPicker
                        variations={product.acfData.productInformation.variations}
                        activeVariation={e => setChecked(e)}
                        checked={checked}
                    />
                    <AddToCartButtonWrapper>
                        <AddToCartButton
                            productId={activeVariation ? activeVariation.id : product.cmsId}
                            buttonText="Add to cart" // Todo replace hardcoded text
                        />
                    </AddToCartButtonWrapper>
                    <div>
                        {product.acfData.productInfoSections.map((section) => {
                            if (section.module === 'text_section') {
                                return <ProductHighlightText heading={section.heading} text={section.text} />;
                            }
                            if (section.module === 'list_section') {
                                return <ProductHighlightList heading={section.heading} items={section.bulletPoints} />;
                            }
                            return null;
                        })}
                    </div>
                </InfoWrapper>
            </ProductWrapper>
            {renderPageContent}
        </>
    );
};

export default ProductPage;

// @TODO: Improve PropTypes

ProductPage.propTypes = {
    data: PropTypes.exact({
        mainCategory: PropTypes.exact({
            edges: PropTypes.arrayOf(
                PropTypes.exact({
                    node: PropTypes.exact({
                        'acf_data': PropTypes.string,
                        'cat_ID': PropTypes.number,
                        id: PropTypes.string,
                        name: PropTypes.string,
                    })
                })
            )
        })
    }).isRequired,
    product: PropTypes.shape({
        acfData: PropTypes.shape({
            productInformation: PropTypes.shape({
                readMore: PropTypes.object,
                variations: PropTypes.arrayOf(PropTypes.object),
                fullDescription: PropTypes.array, // Wysiwyg array
            }),
            productInfoSections: PropTypes.arrayOf(PropTypes.object),
            pageContent: PropTypes.arrayOf(
                PropTypes.shape({
                    module: PropTypes.string,
                    title: PropTypes.string,
                    data: PropTypes.shape({
                        module: PropTypes.string,
                        data: PropTypes.object,
                    }),
                })
            ),
        }),
        heading: PropTypes.string,
        links: PropTypes.array,
        published: PropTypes.string,
        cmsId: PropTypes.number,
        name: PropTypes.string,
        mainCategoryName: PropTypes.string,
        edition: PropTypes.string,
        imageData: PropTypes.arrayOf(PropTypes.exact({
            fluid: PropTypes.object,
        })),
        productImage: PropTypes.exact({
            fluid: PropTypes.object,
        }),
    }).isRequired,
};
